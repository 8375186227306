import type { ColorArray } from '../types/utils';

export const RacemapColors = {
  CloudBlue: '#ced9e3',
  PaleBlue: '#36739a',
  LightBlue: '#f0f5fa',
  DarkRed: '#721c25',
  DangerRed: '#dc3545',
  BaseRed: '#ff4646',
  AltRed: '#f53838',
  LightRed: '#f8d7da',
  LightLightRed: '#fef2f2',
  LightYellow: '#fcda9f',
  LightLightYellow: '#fefbea',
  BaseYellow: '#ffff00',
  LightGreen: '#63d463',
  BaseGreen: '#2e9e2e',
  DarkGreen: '#008000',
  LightGray: '#dee2e6',
  LightLightGray: '#f2f2f2',
  Gray: '#ced4da',
  DarkGray: '#646464',
  DarkYellow: '#b88a3b',
  DarkBlue: '#254a61',
  LightOrange: '#ffa500',
  Orange: '#b4530a',
  DarkDarkGray: '#202020',

  Headline: '#404040',
};

export const DefaultColorArray: ColorArray = [54, 115, 154, 255];
export const DefaultColorArraySplit: ColorArray = [54, 115, 0, 255];
export const DefaultColorObject = {
  r: 54,
  g: 115,
  b: 154,
  a: 1,
};

export const RacemapPlayStoreLink = 'https://play.google.com/store/apps/details?id=racemap.de';
export const RacemapAppStoreLink = 'https://apps.apple.com/us/app/racemap/id640797527';

// based on bootstrap breakpoints (min-width: 992px)
export const MaxMobileWindowWidth = 991;
