import { ObjectId } from '@racemap/sdk/schema/base';
import Ajv from 'ajv';
import type { Feature, FeatureCollection, Geometry, Position } from 'geojson';
import type { Immutable } from 'immer';
import type { RacemapAPIClient } from '../api-client';
import { TrackerActivationType } from '../consts/trackers';
import FeatureCollectionSchema from '../jsonSchemas/FeatureCollection.json';
import { extractJsonOrXml } from '../service-utils/fetch';
import { type GenericImportSource, unwrapStarters } from '../service-utils/pull-starters';
import { validateGenericImport } from '../service-utils/pull-starters/validateGenericImport';
import type {
  GenericImportCheckResult,
  GenericImportStarterRecord,
  RREventId,
} from '../types/events';
import {
  type EditableGeoObject,
  type EventTrackObject,
  type GeoElementObject,
  GeoElementTypes,
  type LayerObject,
  LayerType,
  type LineStringObject,
  type POIObject,
  type PointObject,
  type PointOnLineStringObject,
  type PolygonObject,
  RacemapGeoTypes,
  type SplitObject,
  type WMSLayerObject,
} from '../types/geos';
import type { ArchivedTracker, TrackerDocument, TrackerObject } from '../types/trackers';
import type { Point } from '../types/types';
import type { ID, PrimitiveType } from '../types/utils';
import { checkLuhnAlgorithm } from './checkLuhnAlgorithm';
import { isNotEmptyString } from './utils';

const MONGO_ID_REGEX = RegExp(/^[0-9a-fA-F]{24}$/);
export const APP_ID_REGEX = RegExp(/^[0-9a-f\-]{36}$/); // e6ab2a35-adcc-4bd5-bdf3-87d023302511
const TRACKER_ID_REGEX = RegExp(/^[0-9]{10,}$/); // 860599000780923
const EMAIL_REGEX = RegExp(/^[\w\-.+]+@([\w\-\.]+)\.[\w\-]{2,4}$/);

const ajv = new Ajv();
export const validateFeatureCollection = ajv.compile<FeatureCollection>(FeatureCollectionSchema);

export const isDefined = <T>(element: T | undefined | null): element is T => {
  return element != null;
};

export const isNotNull = <T>(v: T | null | undefined): v is T => {
  return v != null;
};

export const isPrimitive = (value: any): value is PrimitiveType => {
  return (typeof value !== 'object' && typeof value !== 'function') || value === null;
};

export function isValidDate(d: Date): boolean {
  return d instanceof Date && !Number.isNaN(d.valueOf());
}

export function isValidISODate(date: unknown): date is string {
  return typeof date === 'string' && !Number.isNaN(Date.parse(date));
}

export function validateNumber(n: any): n is number {
  return n != null && !Number.isNaN(n) && Number.isFinite(n);
}

export function validateString(s: any): s is string {
  return s != null && Object.prototype.toString.call(s) === '[object String]';
}

export function validateNoEmptyString(s: any): s is string {
  return validateString(s) && s !== '';
}

export function validateMongoId(mongoId: any): mongoId is ID {
  return mongoId instanceof ObjectId || (validateString(mongoId) && MONGO_ID_REGEX.test(mongoId));
}

export function isValidObjectIdLike<I extends string | ObjectId>(
  id: I | null | undefined,
): id is I {
  return id != null && ObjectId.isValid(id);
}

export function validateTrackerId(trackerId: any): trackerId is string {
  return validateString(trackerId) && TRACKER_ID_REGEX.test(trackerId);
}

export function appIdBelongsToRacemapApp(appId?: string | null): appId is string {
  // app id has the form of a uuid that is the structur of the racemap app ids
  // for example 3123320d-a494-4f70-8f6e-40377462a2e5
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    appId || '',
  );
}

export function validateUrl(url: string): url is string {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
  } catch {
    return false;
  }
}

export function validatePosition(position: unknown): position is Position {
  return (
    Array.isArray(position) &&
    (position.length === 2 || position.length === 3) &&
    position.every((p) => typeof p === 'number')
  );
}

export function validateCoordinates(positions: unknown): positions is Array<Position> {
  return Array.isArray(positions) && positions.every((p) => validatePosition(p));
}

export function validatePoints(positions: unknown): positions is Array<Point> {
  return Array.isArray(positions) && positions.every((p) => validatePoint(p));
}

export function validateFeature(feature: any): feature is Feature {
  return (
    feature != null &&
    feature.type === 'Feature' &&
    feature.geometry != null &&
    feature.properties != null
  );
}

export function validatePoint(point: Point): point is Point {
  if (
    Object.prototype.toString.call(point) === '[object Object]' &&
    'lat' in point &&
    'lng' in point &&
    'time' in point
  ) {
    if (validateString(point.time)) {
      const time = new Date(point.time);
      if (Number.isNaN(time.getTime())) {
        return false;
      }
      point.time = time;
    }
    return (
      validateNumber(point.lat) &&
      validateNumber(point.lng) &&
      validateNumber(point.time) &&
      (point.elv == null || validateNumber(point.elv))
    );
  }
  return false;
}

export function validateRREventId(eventId: unknown): eventId is RREventId {
  // event id has to be an string with only digits and a length of 4 to 8
  return eventId != null && typeof eventId === 'string' && /^\d{4,8}$/.test(eventId);
}
export function isValidRREventId(eventId: unknown): eventId is RREventId {
  return validateRREventId(eventId);
}
export function isValidRRCustomerId(customerId: string) {
  return /^\+?[1-9]\d*$/.test(customerId);
}

// `keyof any` is short for "string | number | symbol"
// since an object key can be any of those types, our key can too
// in TS 3.0+, putting just "string" raises an error
export function hasKey<O extends object>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export const checkGenericImport = async (
  sourceURL: string | undefined,
  keys: Set<string>,
  apiClient: RacemapAPIClient,
): Promise<GenericImportCheckResult> => {
  const result = {
    errors: [],
    failures: [],
    validCount: 0,
  } as GenericImportCheckResult;

  try {
    const content = await apiClient.getRemoteContent(sourceURL);
    const starterRaw = await extractJsonOrXml(content);
    const starters = unwrapStarters(starterRaw as GenericImportSource<GenericImportStarterRecord>);

    if (starters == null) throw new Error('Receive empty generic starter file!');

    // TODO: allow unknown input and validate the struct in the validateGenericImport function
    return validateGenericImport(
      starters as Array<GenericImportStarterRecord>,
      keys,
      result.errors,
    );
  } catch (exception) {
    if (exception instanceof Error) {
      result.errors.push(exception.message);
    }
  }

  return result;
};

export function checkGeojson(
  geojson: unknown,
): geojson is FeatureCollection<Geometry, GeoJSON.GeoJsonProperties> {
  return validateFeatureCollection(geojson);
}

export const isValidEmail = (email: unknown): email is string => {
  if (!validateString(email)) return false;

  return EMAIL_REGEX.test(email);
};

export function isGeoElement(element: any): element is GeoElementObject {
  return element?.geometry !== undefined && element?.properties != null;
}

export function isEditableGeoElement(element: any): element is EditableGeoObject {
  return isLineString(element) || isPoint(element) || isPointOnLineString(element);
}

export function isLineString(element: any): element is LineStringObject {
  return isGeoElement(element) && element?.geometry?.type === GeoElementTypes.LineString;
}

export function isRacemapTrack(element: any): element is EventTrackObject {
  return isLineString(element) && element?.properties?.racemapType === RacemapGeoTypes.TRACK;
}

export function isPointOfInterest(element: any): element is POIObject {
  return isPoint(element) && element?.properties?.racemapType === RacemapGeoTypes.POI;
}

export function isPoint(element: any): element is PointObject {
  return element?.geometry?.type === GeoElementTypes.Point;
}

export function isPolygon(element: any): element is PolygonObject {
  return element?.geometry?.type === GeoElementTypes.Polygon;
}

export function isPointOnLineString(element: any): element is PointOnLineStringObject {
  return (
    element?.geometry?.type === GeoElementTypes.Point &&
    isNotEmptyString(element?.properties?.lineStringId)
  );
}

export function isStartSplit(element: PointOnLineStringObject) {
  return isPointOnLineString(element) && element?.properties?.coordinateIndex === 0;
}

export function isFinishSplit(element: PointOnLineStringObject, lineString: LineStringObject) {
  return (
    isPointOnLineString(element) &&
    element?.properties?.coordinateIndex === lineString.geometry.coordinates.length - 1
  );
}

export function isSamePointOnLineString(pointA: any, pointB: any): boolean {
  return (
    isPointOnLineString(pointA) &&
    isPointOnLineString(pointB) &&
    pointB.properties.coordinateIndex === pointA.properties.coordinateIndex &&
    pointB.properties.lineStringId === pointA.properties.lineStringId
  );
}

export function isExternalLayer(element: any): element is LayerObject {
  return element?.properties?.racemapType === RacemapGeoTypes.EXTERNAL_LAYER;
}

export function isWMSRasterLayer(element: any): element is WMSLayerObject {
  return isExternalLayer(element) && element.properties.layerType === LayerType.WMS;
}

export function isSplitPoint(element: any): element is SplitObject {
  return isPoint(element) && element?.properties?.racemapType === RacemapGeoTypes.SPLIT;
}

export function isArchivedTracker(
  tracker: Immutable<TrackerObject> | TrackerDocument | null,
): tracker is ArchivedTracker {
  return tracker?.activation != null && tracker?.activation.type === TrackerActivationType.ARCHIVED;
}

export function isValidUUID(uuid: string): boolean {
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    uuid,
  );
}

/**
 * Checks if the given ICCID (Integrated Circuit Card Identifier) is valid.
 *    iccid has 5 parts
 * MII + CC + IIN + IAIN + Check digit
 * MII: Major Industry Identifier | 2 digits
 * CC: Country Code | 2-3 digits
 * IIN: Issuer Identifier Number | 1-4 digits
 * IAIN: Individual Account Identification Number | 6-10 digits
 * Check digit: Luhn algorithm | 1 digit
 * example 89 + 45 + 73 + 0000271204 + 3
 *
 * @param iccid - The ICCID to validate.
 * @returns A boolean indicating whether the ICCID is valid or not.
 * @see {@link https://en.wikipedia.org/wiki/Integrated_Circuit_Card_Identifier}
 *
 */
export function isValidICCID(iccid: string): boolean {
  // iccid has only numbers
  // min length is 10
  // max length is 20
  if (!/^[0-9]{10,20}$/.test(iccid)) return false;

  // check luhn algorithm
  return checkLuhnAlgorithm(iccid);
}
