import { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { PRICING_VERSIONS } from '@racemap/utilities/consts/billing';
import { getCurrentPricingVersion } from '@racemap/utilities/functions/billing';
import { DateTime } from 'luxon';

export const getCurrentProducts = (now: DateTime | null): Array<StripeProducts> => {
  const pricing = getCurrentPricingVersion(now ?? DateTime.utc());
  if (pricing === PRICING_VERSIONS.V2024_08) {
    return [
      StripeProducts.GPS_DEVICE,
      StripeProducts.TRANSPONDER,
      StripeProducts.MAP_OR_MONITOR_LOAD,
      StripeProducts.API_OR_LEADERBOARD_LOAD,
      StripeProducts.KEY,
      StripeProducts.SPONSOR_ADDON,
      StripeProducts.API_ADDON,
      StripeProducts.TIMING_ADDON,
      StripeProducts.BASE_PRICE,
      StripeProducts.MONITOR_ADDON,
      StripeProducts.MAP,
    ];
  }

  if (
    pricing === PRICING_VERSIONS.V2016_01 ||
    pricing === PRICING_VERSIONS.V2019_08 ||
    pricing === PRICING_VERSIONS.V2020_09
  ) {
    return [
      StripeProducts.STARTER,
      StripeProducts.PAGE_VIEW,
      StripeProducts.KEY,
      StripeProducts.SPONSOR_ADDON,
      StripeProducts.API_ADDON,
      StripeProducts.TIMING_ADDON,
      StripeProducts.BASE_PRICE,
      StripeProducts.MONITOR_ADDON,
    ];
  }

  throw new Error(`Unknown pricing version: ${pricing}`);
};
