import { StripeProducts } from '@racemap/sdk/schema/billing';
import { HIGH_RESOLUTION_THRESHOLD } from '@racemap/utilities/consts/events';
import { OneSecondInMillis } from '@racemap/utilities/consts/time';
import { Typography } from 'antd';
import type { FC } from 'react';
import { useGetProductPricePerUnit } from 'src/lib/customHooks';
import { formatPrice } from 'src/misc/pricing';

const { Text } = Typography;

export const InterpolationIntervalConfirmModalContent: FC = () => {
  const getPrice = useGetProductPricePerUnit();
  return (
    <>
      The interpolation interval is lower than {HIGH_RESOLUTION_THRESHOLD / OneSecondInMillis}{' '}
      seconds. We will charge you{' '}
      <Text strong>{formatPrice(getPrice(StripeProducts.HIGH_RESOLUTION_ADDON))}</Text> for every
      event cycle, the interval is so low. Are you sure you want to continue?
    </>
  );
};
