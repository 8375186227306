import Fuse, { type IFuseOptions } from 'fuse.js';

export const prepareFuse = <T>(eventsArray: Array<T>, fuseOptions: IFuseOptions<T>) => {
  const fuse = new Fuse(eventsArray, fuseOptions);

  return {
    setCollection: (collection: ReadonlyArray<T>) => {
      fuse.setCollection(collection);
    },
    search: (searchValue: string) => {
      return fuse.search(searchValue);
    },
  };
};
