import type { ESEYE_PACKAGE_ITEM_IDS } from '@racemap/sdk/schema/billing';
import { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { clamp } from '@racemap/utilities/math';
import type { Price } from 'src/components/CostCalculator/getProductPriceFactory';

export const apiClient = RacemapAPIClient.fromWindowLocation();

export const VAT = 0.19;

export const ESEYE_PACKAGE_ITEM_LABEL: Record<ESEYE_PACKAGE_ITEM_IDS, string> = {
  '38131': 'Zone 1 Data (Legacy)',
  '215407': 'Zone 1 Data',
  '57032': 'Zone 2 Data (Legacy)',
  '215410': 'Zone 2 Data',
  '45379': 'Zone 3 Data (Legacy)',
  '215413': 'Zone 3 Data',
  '81692': 'Zone 4 Data (Legacy)',
  '85427': 'MO SMS Zone 1',
  '85430': 'MT SMS',
  '85545': 'API MT SMS',
  '201942': 'SMS DR',
};

export const ZONE_COUNTRIES: Partial<Record<ESEYE_PACKAGE_ITEM_IDS, Array<string>>> = {
  '215407': [
    'Australia',
    'Austria',
    'Belgium',
    'Bulgaria',
    'China',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Grenada',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'Indonesia',
    'Ireland',
    'Israel',
    'Italy',
    'Japan',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Monaco',
    'Montenegro',
    'Montserrat',
    'Netherlands',
    'New Zealand',
    'Norway',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Romania',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Thailand',
    'The Former Yugoslav Republic of Macedonia',
    'United Kingdom',
    'Guernsey',
    'Isle of Man',
    'Jersey',
    'United States',
    'Vatican City State',
  ],
  '215410': [
    'Albania',
    'Andorra',
    'Bosnia and Herzegovina',
    "Lao People's Democratic Republic",
    'Malaysia',
    'Philippines',
    'Turkey',
    'Ukraine',
  ],
  '215413': [
    'Anguilla',
    'Antigua and Barbuda',
    'Azerbaijan',
    'Barbados',
    'Brazil',
    'Canada',
    'Cayman Islands',
    'Chile',
    'Colombia',
    'Dominica',
    'El Salvador',
    'French Guiana',
    'Gibraltar',
    'Guadeloupe',
    'Saint Martin',
    'Jamaica',
    'Kenya',
    'Kuwait',
    'Lesotho',
    'Mexico',
    'Nigeria',
    'Panama',
    'Peru',
    'Qatar',
    'Reunion',
    'Saudi Arabia',
    'Taiwan',
    'Turks and Caicos Islands',
    'Uganda',
    'United Arab Emirates',
    'United Republic of Tanzania',
  ],
  '38131': [
    'Anguilla',
    'Antigua and Barbuda',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Barbados',
    'Belgium',
    'Brazil',
    'Bulgaria',
    'Canada',
    'Cayman Islands',
    'Chile',
    'China',
    'Colombia',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Dominica',
    'El Salvador',
    'Estonia',
    'Finland',
    'France',
    'French Guiana',
    'Germany',
    'Gibraltar',
    'Greece',
    'Grenada',
    'Guadeloupe',
    'Saint Martin',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Kenya',
    'Kuwait',
    'Latvia',
    'Lesotho',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Malaysia',
    'Malta',
    'Mexico',
    'Monaco',
    'Montserrat',
    'Netherlands',
    'New Zealand',
    'Nigeria',
    'Norway',
    'Panama',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'San Marino',
    'Saudi Arabia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Taiwan',
    'Thailand',
    'Turks and Caicos Islands',
    'Uganda',
    'United Kingdom',
    'Guernsey',
    'Isle of Man',
    'Jersey',
    'United Republic of Tanzania',
    'United States',
    'Vatican City State',
  ],
  '57032': ['Indonesia', 'Montenegro', 'Serbia', 'The Former Yugoslav Republic of Macedonia'],
  '45379': [
    'Albania',
    'Andorra',
    'Bosnia and Herzegovina',
    "Lao People's Democratic Republic",
    'Turkey',
    'Ukraine',
  ],
  '81692': ['United Arab Emirates'],
};

function getTrackerBreaks() {
  return [4, 19, 39, 99];
}
export function getTrackerBuyPrice(buyTracker: number, isPro: boolean) {
  const breaks = getTrackerBreaks();
  return (
    clamp(0, buyTracker, breaks[0]) * getTrackerBuyPriceSingle(breaks[0], isPro) +
    clamp(0, buyTracker - breaks[0], breaks[1] - breaks[0]) *
      getTrackerBuyPriceSingle(breaks[0] + 1, isPro) +
    clamp(0, buyTracker - breaks[1], breaks[2] - breaks[1]) *
      getTrackerBuyPriceSingle(breaks[1] + 1, isPro) +
    clamp(0, buyTracker - breaks[2], breaks[3] - breaks[2]) *
      getTrackerBuyPriceSingle(breaks[2] + 1, isPro) +
    Math.max(buyTracker - breaks[3], 0) * getTrackerBuyPriceSingle(breaks[3] + 1, isPro)
  );
}
export function getTrackerBuyPriceSingle(buyTracker: number, isPro: boolean) {
  const breaks = getTrackerBreaks();
  if (buyTracker <= breaks[0]) {
    return isPro ? 15000 : 4500;
  }
  if (buyTracker <= breaks[1]) {
    return isPro ? 13500 : 4200;
  }
  if (buyTracker <= breaks[2]) {
    return isPro ? 12000 : 3900;
  }
  if (buyTracker <= breaks[3]) {
    return isPro ? 10500 : 3600;
  }
  return isPro ? 9000 : 3300;
}
export function getTrackerRentPrice(rentTracker: number, isPro: boolean) {
  const breaks = getTrackerBreaks();
  return (
    clamp(0, rentTracker, breaks[1]) * getTrackerRentPriceSingle(breaks[1], isPro) +
    clamp(0, rentTracker - breaks[1], breaks[2] - breaks[1]) *
      getTrackerRentPriceSingle(breaks[1] + 1, isPro) +
    clamp(0, rentTracker - breaks[2], breaks[3] - breaks[2]) *
      getTrackerRentPriceSingle(breaks[2] + 1, isPro) +
    Math.max(rentTracker - breaks[3], 0) * getTrackerRentPriceSingle(breaks[3] + 1, isPro)
  );
}
export function getTrackerRentSecondWeekPrice(rentTracker: number, isPro: boolean) {
  return getTrackerRentPrice(rentTracker, isPro) * 0.5;
}
export function getTrackerRentPriceSingle(rentTracker: number, isPro: boolean) {
  const breaks = getTrackerBreaks();
  if (rentTracker <= breaks[1]) {
    return isPro ? 2200 : 1200;
  }
  if (rentTracker <= breaks[2]) {
    return isPro ? 2000 : 1100;
  }
  if (rentTracker <= breaks[3]) {
    return isPro ? 1800 : 1000;
  }
  return isPro ? 1600 : 900;
}

export function formatNumber(num?: number | null): string {
  if (num == null || num === 0) return '-';

  return num.toLocaleString('en-US', {
    useGrouping: true,
    notation: 'compact',
  });
}

export function formatEuroPrice(amount: number) {
  return `€${(amount / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  })}`;
}

export function formatEuroPriceWithoutDigit(amount: number) {
  return `€${(amount / 100).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  })}`;
}

export function getStripeProductKey(input: string | undefined | null): StripeProducts | undefined {
  if (input == null) return undefined;

  return input.replace(/_reseller|_regular/, '') as StripeProducts;
}

export function formatPriceWithVAT(amount: number, vatInclusive: boolean, silent = false) {
  if (silent) return formatEuroPrice(amount);

  return vatInclusive
    ? `${formatEuroPrice(amount)} incl. VAT`
    : `${formatEuroPrice(amount)} excl. VAT`;
}

export function formatPrice(price?: Price) {
  if (price == null) return '-';
  return `${formatEuroPrice(price.amount)} excl. VAT`;
}

export function getLabelForBillingItemType(
  billingItemType: BillableItemTypes | StripeProducts,
): string {
  switch (billingItemType) {
    case BillableItemTypes.BASE_PRICE:
    case StripeProducts.BASE_PRICE:
      return 'Base price';
    case BillableItemTypes.EVENT_CYCLE:
    case StripeProducts.MAP:
      return 'Maps';
    case BillableItemTypes.KEY:
    case StripeProducts.KEY:
      return 'Used Keys';
    case BillableItemTypes.SPONSOR:
    case StripeProducts.SPONSOR_ADDON:
      return 'Add-On: Sponsor';
    case BillableItemTypes.TIMING:
    case StripeProducts.TIMING_ADDON:
      return 'Add-On: Leaderboard';
    case BillableItemTypes.MONITOR:
    case StripeProducts.MONITOR_ADDON:
      return 'Add-On: Monitor';
    case BillableItemTypes.DATA_FEED:
    case StripeProducts.API_ADDON:
      return 'Add-On: Data API';
    case BillableItemTypes.HIGH_RESOLUTION:
    case StripeProducts.HIGH_RESOLUTION_ADDON:
      return 'Add-On: High Resolution';
    case BillableItemTypes.DATA_USAGE_ZONE_1:
    case StripeProducts.DATA_USAGE_ZONE_1:
      return 'Data usage zone 1';
    case BillableItemTypes.DATA_USAGE_ZONE_2:
    case StripeProducts.DATA_USAGE_ZONE_2:
      return 'Data usage zone 2';
    case BillableItemTypes.DATA_USAGE_ZONE_3:
    case StripeProducts.DATA_USAGE_ZONE_3:
      return 'Data usage zone 3';
    case StripeProducts.DATA_USAGE:
      return 'Data usage';
    case BillableItemTypes.SIM_CARD:
    case StripeProducts.SIM_CARD_ACTIVATION:
      return 'Subscription: SIM card';
    case BillableItemTypes.APP:
    case StripeProducts.CUSTOM_APP:
      return 'App';
    case StripeProducts.TRACKER_MANAGEMENT:
      return 'Tracker Management';
    case StripeProducts.GPS_DEVICE:
    case BillableItemTypes.GPS_DEVICE:
      return 'GPS Device';
    case StripeProducts.TRANSPONDER:
    case BillableItemTypes.TRANSPONDER:
      return 'Transponder';
    case StripeProducts.MAP_OR_MONITOR_LOAD:
      return 'Map and Monitor Loads';
    case StripeProducts.API_OR_LEADERBOARD_LOAD:
      return 'API and Leaderboard Loads';

    case BillableItemTypes.FREE_EVENT_CYCLE:
      return 'Free Event Cycle';
    case BillableItemTypes.FREE_TIMING:
      return 'Free Leaderboard';
    case BillableItemTypes.FREE_MONITOR:
      return 'Free Monitor';
    case BillableItemTypes.FREE_DATA_FEED:
      return 'Free API';
    case BillableItemTypes.FREE_HIGH_RESOLUTION:
      return 'Free High Resolution';
    case BillableItemTypes.FREE_SPONSOR:
      return 'Free Sponsor';
    case BillableItemTypes.STARTER:
    case StripeProducts.STARTER:
      return 'Devices (Legacy)';
    case BillableItemTypes.PAGE_VIEW:
    case StripeProducts.PAGE_VIEW:
      return 'Loads (Legacy)';
    case BillableItemTypes.EXTEND_EVENT:
      return 'Extend Event (Legacy)';
    case BillableItemTypes.SMS:
    case StripeProducts.SMS:
      return 'SMS';
    default:
      throw new Error(`Unknown billing item type: ${billingItemType}`);
  }
}
