import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ObjectId } from '@racemap/sdk/schema/base';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Button, Table, type TableColumnsType, type TableProps } from 'antd';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { IconHolder } from 'src/components/Icon';

interface DataType extends Record<string, unknown> {
  id: string | number | ObjectId;
}

interface Props<DT extends DataType> extends TableProps<DT> {
  columns: TableColumnsType<DT>;
  dataSource: Array<DT>;
  onOrderChange?: (data: Array<DT>, entry1: DT, entry2: DT) => void;
}
export type ColumnsType<DataType> = TableColumnsType<DataType>;

export function SortableTable<DT extends DataType>({
  onOrderChange,
  columns,
  dataSource,
  ...props
}: Props<DT>) {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((record) => record.id.toString() === active?.id);
      const overIndex = dataSource.findIndex((record) => record.id.toString() === over?.id);
      onOrderChange?.(
        arrayMove(dataSource, activeIndex, overIndex),
        dataSource[activeIndex],
        dataSource[overIndex],
      );
    }
  };

  const columnsWithSortHandler: ColumnsType<DT> = [
    { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
    ...columns,
  ];

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.id.toString())}
        strategy={verticalListSortingStrategy}
      >
        <Table<DT>
          {...props}
          rowKey={(record) => record.id.toString()}
          components={{ body: { row: Row } }}
          dataSource={dataSource}
          columns={columnsWithSortHandler}
        />
      </SortableContext>
    </DndContext>
  );
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<IconHolder color={RacemapColors.DarkGray} size="xl" />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });
  const isPlaceholder = props.className?.includes('ant-table-placeholder');

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging && !isPlaceholder ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        className={classNames(props.className, { 'is-dragging': isDragging && !isPlaceholder })}
      />
    </RowContext.Provider>
  );
};
