import { ObjectId } from '@racemap/sdk/schema/base';
import {
  type EntityEvent,
  EntityEventState,
  type EntityEventStateWithoutNew,
  EntityEventType,
  type EntityEvents,
  EntityType,
} from '@racemap/sdk/schema/entityEvents';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import {
  AuthorizationStates,
  ModulesStates,
  VisibilityStates,
} from '@racemap/utilities/consts/events';
import { decodeBinary } from '@racemap/utilities/functions/codec';
import { currentEventTimes } from '@racemap/utilities/functions/event';
import {
  type TagsCollectionIntermediated,
  extendIntermediatedTagsCollection,
  getTagsWithCountsFromIntermediated,
} from '@racemap/utilities/functions/tags';
import {
  type PointWithSpeedAndOffset,
  type TagsCollection,
  convertTracks,
  getPseudoDeviceStateForRepeatEvent,
  getVirtualRaceDistance,
  getVirtualRaceDuration,
  prepareStarterTracks,
  testEventHasVirtDistance,
  testEventHasVirtDuration,
} from '@racemap/utilities/functions/timing';
import { isTrackerOnline } from '@racemap/utilities/functions/trackers';
import { isDefined } from '@racemap/utilities/functions/validation';
import { calculateDistanceInMeter } from '@racemap/utilities/point_utils';
import {
  EventLoadTypes,
  type RacemapEvent,
  type RacemapStarter,
} from '@racemap/utilities/types/events';
import type { SplitObject } from '@racemap/utilities/types/geos';
import {
  type StarterResult,
  type StarterResults,
  StarterStatus,
} from '@racemap/utilities/types/monitor';
import type { LatLng } from '@racemap/utilities/types/trackping';
import type { PointWithOffset } from '@racemap/utilities/types/types';
import { type Draft, produce } from 'immer';
import { DateTime } from 'luxon';
import moment from 'moment';
import { toast } from 'react-toastify';
import { create } from 'zustand';
import { isValidStartOrFinishTime } from '../../components/utils/isValidStartOrFinishTime';

interface MonitorStore {
  event: RacemapEvent | null;
  alerts: Map<string, EntityEvent>;
  tags: TagsCollection;
  startersRaw: Array<RacemapStarter>;
  starters: StarterResults;
  splits: Array<SplitObject>;
  liveBinary: ArrayBuffer;
  chunkedShadowtrack: Array<LatLng>;
  triggeredLoad: boolean;
  isLoadingData: boolean;
  isLoadingLiveData: boolean;
  actions: {
    loadEvent: (eventIdOrSlug: string | ObjectId) => Promise<void>;
    loadAlerts: () => Promise<void>;
    loadData: (eventIdOrSlug: string | ObjectId) => Promise<void>;
    loadLiveData: () => Promise<void>;
    loadChunkedShadowtrack: () => Promise<void>;
    loadLiveBinary: () => Promise<void>;
    loadSplits: () => Promise<void>;
    loadStarters: () => Promise<void>;
    setTriggeredLoad: () => Promise<void>;
    resetStore: () => void;
    prepareStarterData: () => void;
    solveAlerts: (
      alertIds: Array<string>,
      userId: string | ObjectId,
      state: EntityEventStateWithoutNew,
    ) => Promise<void>;
  };
}

type DraftState = Draft<MonitorStore>;

const apiClient = RacemapAPIClient.fromWindowLocation();
export const pseudoFrontendUserId = new ObjectId('5f7b3b3b7f7b7b7b7f7b7b7b');

export const useMonitorStore = create<MonitorStore>((set, get) => ({
  event: null,
  alerts: new Map(),
  tags: new Map(),
  startersRaw: [],
  starters: [],
  liveBinary: new ArrayBuffer(0),
  chunkedShadowtrack: [],
  splits: [],
  triggeredLoad: false,
  isLoadingData: false,
  isLoadingLiveData: false,
  actions: {
    loadData: async (eventIdOrSlug: string | ObjectId) => {
      if (get().isLoadingData) return;

      try {
        produce((s: DraftState) => {
          s.isLoadingData = true;
        });
        await get().actions.loadEvent(eventIdOrSlug);
        const event = get().event;
        if (event == null) return;

        await Promise.all([
          get().actions.loadAlerts(),
          get().actions.loadChunkedShadowtrack(),
          get().actions.loadLiveBinary(),
          get().actions.loadSplits(),
          get().actions.loadStarters(),
        ]);
        get().actions.prepareStarterData();

        if (get().triggeredLoad) return;
        await get().actions.setTriggeredLoad();
      } catch (e) {
        console.error(e);
      } finally {
        produce((s: DraftState) => {
          s.isLoadingData = false;
        });
      }
    },
    loadLiveData: async () => {
      if (get().isLoadingLiveData || get().isLoadingData) return;

      try {
        produce((s: DraftState) => {
          s.isLoadingLiveData = true;
        });
        const event = get().event;
        if (event == null) return;

        await Promise.all([
          get().actions.loadAlerts(),
          get().actions.loadLiveBinary(),
          get().actions.loadStarters(),
        ]);
        get().actions.prepareStarterData();
      } catch (e) {
        console.error(e);
      } finally {
        produce((s: DraftState) => {
          s.isLoadingLiveData = false;
        });
      }
    },
    loadStarters: async () => {
      const event = get().event;
      if (event == null) return;

      const starters = await apiClient.getEventStarters(event.id);

      set(
        produce((s: DraftState) => {
          s.startersRaw = starters;
        }),
      );
    },
    loadSplits: async () => {
      const event = get().event;
      if (event == null) return;

      const splits = await apiClient.getEventSplits(event.id);

      set(
        produce((s: DraftState) => {
          s.splits = splits;
        }),
      );
    },
    loadChunkedShadowtrack: async () => {
      const event = get().event;
      if (event == null) return;

      const shadowtrack = await apiClient.getEventChunkedShadowGeo(event.id);

      set(
        produce((s: DraftState) => {
          s.chunkedShadowtrack = shadowtrack;
        }),
      );
    },
    loadLiveBinary: async () => {
      const event = get().event;
      if (event == null) return;

      const liveBinary = await apiClient.getEventPointsRaw(event.id);

      set(
        produce((s: DraftState) => {
          s.liveBinary = liveBinary;
        }),
      );
    },
    loadEvent: async (eventIdOrSlug) => {
      const event = await apiClient.getEvent(eventIdOrSlug.toString());

      set(
        produce((s: DraftState) => {
          s.event = event;
        }),
      );
    },
    loadAlerts: async () => {
      const event = get().event;
      if (event == null) return;

      const starters = get().startersRaw;
      const [startTime, endTime] = currentEventTimes(event);
      if (startTime == null || endTime == null) return;

      const startAlertFrame = DateTime.fromMillis(startTime).minus({ hours: 8 }).toJSDate();
      const endAlertFrame = DateTime.fromMillis(endTime).plus({ hours: 8 }).toJSDate();
      const starterIds = starters.map((starter) => starter.deviceId).filter(isDefined);

      if (starterIds.length === 0) return;

      const alerts = await apiClient.listEntityEvents({
        entityIds: starterIds,
        startTime: startAlertFrame,
        endTime: endAlertFrame,
      });

      set(
        produce((s: DraftState) => {
          for (const alert of alerts) {
            s.alerts.set(alert.id.toHexString(), alert);
          }
        }),
      );
    },
    setTriggeredLoad: async () => {
      if (get().triggeredLoad) return;

      const event = get().event;
      if (event == null) return;
      if (event.visibility === VisibilityStates.ARCHIVED) return;
      if (event.modules.monitor.state !== ModulesStates.ACTIVATED) return;
      if (event.authorization === AuthorizationStates.NONE) return;

      await apiClient.triggerEventLoad(event.id, EventLoadTypes.MONITOR_LOADS);

      set(
        produce((s: DraftState) => {
          s.triggeredLoad = true;
        }),
      );
    },
    resetStore: () => {
      set(
        produce((s: DraftState) => {
          s.event = null;
          s.alerts = new Map();
          s.tags = new Map();
          s.starters = [];
          s.triggeredLoad = false;
          s.isLoadingData = false;
          s.isLoadingLiveData = false;
        }),
      );
    },
    prepareStarterData: async () => {
      const {
        event,
        startersRaw: starters,
        splits,
        alerts,
        liveBinary,
        chunkedShadowtrack,
      } = get();

      const preparedData = prepareMonitorData({
        event,
        starters,
        splits,
        alerts: Array.from(alerts.values()),
        liveBinary,
        chunkedShadowtrack,
      });

      set(
        produce((s: DraftState) => {
          s.starters = preparedData.starters || [];
          s.tags = preparedData.tags || new Map();
          for (const alert of preparedData.alerts || []) {
            s.alerts.set(alert.id.toHexString(), alert);
          }
        }),
      );
    },
    solveAlerts: async (
      alertIds: Array<string>,
      userId: string | ObjectId,
      state: EntityEventStateWithoutNew,
    ) => {
      try {
        const stateEditedAt = new Date();

        for (const alertId of alertIds) {
          const alert = get().alerts.get(alertId);
          if (alert == null) continue;

          // Do not solve no movement and away from track alerts, because there local only
          if (
            alert.eventType === EntityEventType.NO_MOVEMENT ||
            alert.eventType === EntityEventType.AWAY_FROM_TRACK
          )
            continue;

          set(
            produce((s: DraftState) => {
              s.alerts.set(alert.id.toHexString(), {
                ...alert,
                state: state,
                stateEditedAt,
                stateEditedBy: new ObjectId(userId),
              });
            }),
          );
        }

        await apiClient.updateEntityEventState(alertIds, state);
        await get().actions.loadAlerts();
        get().actions.prepareStarterData();

        toast.success(`Alerts updated to ${state}`);
      } catch {
        console.error('Failed to update alerts');
        toast.error('Failed to update alerts');

        for (const alertId of alertIds) {
          const alert = get().alerts.get(alertId);
          if (alert == null) continue;

          set(
            produce((s: DraftState) => {
              s.alerts.set(alert.id.toHexString(), {
                ...alert,
                stateEditedAt: null,
                stateEditedBy: null,
              });
            }),
          );
        }
      }
    },
  },
}));

const prepareMonitorData = ({
  event,
  starters,
  splits,
  alerts,
  liveBinary,
  chunkedShadowtrack,
}: {
  event: RacemapEvent | null | undefined;
  starters: Array<RacemapStarter> | null | undefined;
  splits: Array<SplitObject> | null | undefined;
  alerts: EntityEvents | undefined;
  liveBinary: ArrayBuffer | undefined | null;
  chunkedShadowtrack: Array<LatLng> | null | undefined;
}): {
  starters?: StarterResults;
  alerts?: EntityEvents;
  tags?: TagsCollection;
} => {
  const [startTime, endTime] = currentEventTimes(event);
  if (
    startTime == null ||
    endTime == null ||
    event == null ||
    starters == null ||
    alerts == null ||
    liveBinary == null ||
    splits == null
  )
    return {};

  const {
    starters: starterPrepared,
    alerts: alertMapWithFrontendAlerts,
    tags,
  } = prepareStarterForMonitor({
    starters,
    event,
    liveBinary,
    splits,
    backendAlerts: alerts,
    chunkedShadowtrack,
  });

  return {
    starters: starterPrepared,
    alerts: Array.from(alertMapWithFrontendAlerts.values()),
    tags,
  };
};

function prepareAlertsForStarter(alerts: IterableIterator<EntityEvent>): Map<string, EntityEvents> {
  const output: Map<string, EntityEvents> = new Map();

  for (const a of alerts) {
    if (a.entityId == null) continue;

    output.set(a.entityId.toHexString(), [...(output.get(a.entityId.toHexString()) || []), a]);
  }
  return output;
}

export function createAlert(
  eventType: EntityEventType,
  entityType: EntityType,
  point: PointWithOffset,
  duration: number,
  event: RacemapEvent,
  starter: RacemapStarter & { deviceId: string },
  information: string,
  solved: boolean,
): EntityEvent {
  const occuredAt = new Date(point.time.valueOf() - duration);

  return {
    id: new ObjectId(`${starter.id}_${eventType}_${occuredAt}`),
    entityType,
    eventType: eventType,
    entityId: new ObjectId(starter.id),
    occuredAt,
    creatorId: new ObjectId(event.creatorId),
    updaterId: new ObjectId(event.id),
    createdAt: point.time,
    updatedAt: point.time,
    stateEditedAt: solved ? new Date(point.time) : null,
    stateEditedBy: solved ? pseudoFrontendUserId : null,
    location: {
      lat: point.lat,
      lng: point.lng,
    },
    information: information,
    state: solved ? EntityEventState.RESOLVED : EntityEventState.NEW,
  };
}

export function prepareStarterForMonitor({
  starters,
  event,
  liveBinary,
  splits,
  backendAlerts = [],
  chunkedShadowtrack,
}: {
  starters: Array<RacemapStarter>;
  event: RacemapEvent;
  liveBinary: ArrayBuffer;
  splits: Array<SplitObject>;
  backendAlerts: EntityEvents | undefined;
  chunkedShadowtrack: Array<LatLng> | undefined | null;
}): { starters: StarterResults; alerts: Map<string, EntityEvent>; tags: TagsCollection } {
  const starterResults: StarterResults = [];
  const starterTracks = decodeBinary(liveBinary);
  const noMoveSpeed = Math.max(event.minSpeed, 1);
  const eventHasVirtDuration = testEventHasVirtDuration(event);
  const eventHasVirtDistance = testEventHasVirtDistance(event);
  const eventVirtualRaceDuration = getVirtualRaceDuration(event);
  const eventRaceDistance = getVirtualRaceDistance(event, splits);
  const firstTimekeepping =
    splits != null ? splits.find((split) => split.properties.timekeeping) : null;
  const withChunkedShadowtrack = chunkedShadowtrack != null;
  const firstTrackPoint = withChunkedShadowtrack ? chunkedShadowtrack[0] : null;
  const lastTrackPoint = withChunkedShadowtrack
    ? chunkedShadowtrack[chunkedShadowtrack.length - 1]
    : null;
  const noMovealertDuration = event.alertOptions.noMovementDuration;
  const alertsMap: Map<string, EntityEvent> = new Map();

  for (const alert of backendAlerts) {
    alertsMap.set(alert.id.toHexString(), alert);
  }

  prepareStarterTracks(
    convertTracks(starterTracks),
    event,
    eventHasVirtDistance || eventHasVirtDuration,
    chunkedShadowtrack,
  );

  let tagsCollected: TagsCollectionIntermediated = new Map();
  for (const starter of starters) {
    const starterTrack = starterTracks.get(starter.id) as Array<PointWithSpeedAndOffset>;
    tagsCollected = extendIntermediatedTagsCollection(starter, tagsCollected, starters.length);

    if (starterTrack == null) continue;

    let noMoveDuration = 0;
    let noMoveAvgSpeed = 0;
    let deviationDuration = 0;

    for (let i = 1; i < starterTrack.length; i++) {
      const currentStarterPosition = starterTrack[i];
      const previousStarterPosition = starterTrack[i - 1];
      const currentDistance = calculateDistanceInMeter(
        previousStarterPosition.lat,
        previousStarterPosition.lng,
        currentStarterPosition.lat,
        currentStarterPosition.lng,
      );
      const currentDuration =
        currentStarterPosition.time.valueOf() - previousStarterPosition.time.valueOf();
      const currentSpeed = (currentDistance * 3600) / currentDuration;

      if (
        !Array.isArray(firstTrackPoint) ||
        firstTrackPoint.length < 2 ||
        !Array.isArray(lastTrackPoint) ||
        lastTrackPoint.length < 2
      )
        continue;

      if (
        (currentSpeed < noMoveSpeed || (noMoveDuration > 0 && noMoveAvgSpeed < noMoveSpeed)) &&
        currentStarterPosition.offset > 0
      ) {
        const pointDistanceToFirstPoint = withChunkedShadowtrack
          ? calculateDistanceInMeter(
              currentStarterPosition.lat,
              currentStarterPosition.lng,
              firstTrackPoint[1],
              firstTrackPoint[0],
            )
          : null;
        const pointDistanceToLastPoint = withChunkedShadowtrack
          ? calculateDistanceInMeter(
              currentStarterPosition.lat,
              currentStarterPosition.lng,
              lastTrackPoint[1],
              lastTrackPoint[0],
            )
          : null;
        if (
          pointDistanceToFirstPoint != null &&
          pointDistanceToLastPoint != null &&
          (pointDistanceToFirstPoint < 500 || pointDistanceToLastPoint < 500)
        ) {
          noMoveDuration = 0;
          noMoveAvgSpeed = 0;
        } else {
          noMoveDuration = noMoveDuration + currentDuration;
          noMoveAvgSpeed =
            (noMoveAvgSpeed * (noMoveDuration - currentDuration)) / noMoveDuration +
            (currentSpeed * currentDuration) / noMoveDuration;
        }
      } else {
        noMoveDuration = 0;
        noMoveAvgSpeed = 0;
      }

      if (
        noMovealertDuration > 0 &&
        noMoveDuration > noMovealertDuration &&
        (noMoveAvgSpeed > noMoveSpeed || i === starterTrack.length - 1)
      ) {
        const alert = createAlert(
          EntityEventType.NO_MOVEMENT,
          EntityType.STARTERS,
          currentStarterPosition,
          noMoveDuration,
          event,
          starter,
          `${noMoveDuration / 60000}min with a average speed < ${noMoveSpeed}km/h`,
          noMoveAvgSpeed > noMoveSpeed,
        );
        alertsMap.set(alert.id.toHexString(), alert);
        noMoveDuration = 0;
        noMoveAvgSpeed = 0;
      }

      if (
        (currentStarterPosition?.distanceToShadowTrack || 0) >
          event.playerOptions.mappingDistance &&
        withChunkedShadowtrack
      ) {
        const pointDistanceToFirstPoint = calculateDistanceInMeter(
          currentStarterPosition.lat,
          currentStarterPosition.lng,
          firstTrackPoint[1],
          firstTrackPoint[0],
        );
        const pointDistanceToLastPoint = calculateDistanceInMeter(
          currentStarterPosition.lat,
          currentStarterPosition.lng,
          lastTrackPoint[1],
          lastTrackPoint[0],
        );

        if (pointDistanceToFirstPoint < 500 || pointDistanceToLastPoint < 500) {
          deviationDuration = 0;
        } else {
          deviationDuration =
            deviationDuration +
            (currentStarterPosition.time.valueOf() - previousStarterPosition.time.valueOf());
        }
      }

      // workaround because current position seems to have never a distanceToShadowTrack
      const currentDistanceToShadowTrack =
        currentStarterPosition.distanceToShadowTrack ||
        previousStarterPosition.distanceToShadowTrack ||
        Number.POSITIVE_INFINITY;
      if (
        (currentDistanceToShadowTrack != null &&
          currentDistanceToShadowTrack < event.playerOptions.mappingDistance &&
          deviationDuration > 0) ||
        (i === starterTrack.length - 1 &&
          (deviationDuration > 0 || currentStarterPosition.offset < 0))
      ) {
        const alert = createAlert(
          EntityEventType.AWAY_FROM_TRACK,
          EntityType.STARTERS,
          currentStarterPosition,
          deviationDuration,
          event,
          starter,
          `${deviationDuration / 60000}min away from track`,
          currentDistanceToShadowTrack < event.playerOptions.mappingDistance,
        );
        alertsMap.set(alert.id.toHexString(), alert);
        deviationDuration = 0;
      }
    }
  }
  const tags = getTagsWithCountsFromIntermediated(tagsCollected);
  const alertsGroupedByStarter = prepareAlertsForStarter(alertsMap.values());

  for (const starter of starters) {
    const minutesAgo =
      event?.playerOptions.stepWidth > 60000
        ? moment().subtract(event.playerOptions.stepWidth / 60000, 'minutes')
        : moment().subtract(5, 'minutes');
    const starterTrack = (
      starterTracks.get(starter.id) || []
    ).reverse() as Array<PointWithSpeedAndOffset>;
    const alerts = alertsGroupedByStarter.get(starter.id) || [];
    const lastPoint = starterTrack.length > 0 ? starterTrack[0] : null;
    const active = lastPoint != null && moment(lastPoint.time).isAfter(minutesAgo);
    const lastAlert = alerts.find((a) => moment(a.occuredAt).isAfter(minutesAgo)) || null;
    const pointMinutesBefore = active
      ? starterTrack.find((track) => moment(track.time) < minutesAgo)
      : null;
    const trackerActivated = starter.deviceState != null;

    const starterProgress = getCurrentStarterProgress({
      eventHasVirtDuration,
      eventVirtualRaceDuration,
      firstTimekeepping,
      eventRaceDistance,
      starterTrack,
    });

    const deviceState = event.isRepeating
      ? getPseudoDeviceStateForRepeatEvent(starter, lastPoint, starterProgress.progress)
      : starter.deviceState;

    if (pointMinutesBefore == null || lastPoint == null) {
      starterResults.push({
        ...starter,
        track: starterTrack,
        lastAlert,
        alerts,
        progress: starterProgress,
        lastPoint,
        gpsFix: deviceState?.gpsFix || null,
        online: isTrackerOnline(deviceState),
        active,
        battery: deviceState?.battery || null,
        trackerActivated,
        deviation: null,
        currentSpeed: null,
        currentMove: null,
        status: getStatusOfStarter(starter, event),
        deviceState,
      });
      continue;
    }

    const distanceLastMins = calculateDistanceInMeter(
      pointMinutesBefore.lat,
      pointMinutesBefore.lng,
      lastPoint.lat,
      lastPoint.lng,
    );
    const speedLastMins =
      lastPoint.time != null && pointMinutesBefore.time != null
        ? (distanceLastMins * 3600) / (lastPoint.time.valueOf() - pointMinutesBefore.time.valueOf())
        : 0;

    const starterResult: StarterResult = {
      ...starter,
      progress: starterProgress,
      lastPoint,
      gpsFix: deviceState?.gpsFix || null,
      online: isTrackerOnline(deviceState),
      active,
      battery: deviceState?.battery || null,
      trackerActivated,
      deviation: getDevation({ withChunkedShadowtrack, lastPoint }),
      currentSpeed: Math.round(speedLastMins),
      currentMove: Math.round(Math.abs(distanceLastMins)),
      lastAlert,
      alerts,
      track: starterTrack,
      status: getStatusOfStarter(starter, event),
      deviceState,
    };

    // set infos if tracker is activated in tracker managment
    if (starter.deviceState != null) {
      starterResult.gpsFix = starter.deviceState.gpsFix || null;
    }

    starterResults.push(starterResult);
  }

  return { starters: starterResults, alerts: alertsMap, tags };
}

const getStatusOfStarter = (
  { tags, times }: RacemapStarter,
  event: RacemapEvent,
): StarterStatus | null => {
  const status = tags.status || tags.Status || tags.state || tags.State;
  if (status != null) return status as StarterStatus;
  if (times.end) {
    if (isValidStartOrFinishTime(new Date(times.end), event)) return StarterStatus.FINISHED;
  }
  return null;
};

const getDevation = ({
  withChunkedShadowtrack,
  lastPoint,
}: { withChunkedShadowtrack: boolean; lastPoint: PointWithSpeedAndOffset }): number | null => {
  if (!withChunkedShadowtrack) return -1;
  if (lastPoint.distanceToShadowTrack != null && lastPoint.distanceToShadowTrack > 1000)
    return lastPoint.distanceToShadowTrack;

  return null;
};

function getCurrentStarterProgress({
  eventHasVirtDuration,
  eventVirtualRaceDuration,
  firstTimekeepping,
  eventRaceDistance,
  starterTrack,
}: {
  eventHasVirtDuration: boolean;
  eventVirtualRaceDuration: number;
  firstTimekeepping?: SplitObject | null;
  eventRaceDistance: number;
  starterTrack: Array<PointWithSpeedAndOffset>;
}): { progress: number; currentDistance: number | null; currentDuration: number | null } {
  let currentDuration = null;
  let currentDistance = null;
  let progress = 0;

  for (const element of starterTrack) {
    const currentStarterPosition = element;

    if (currentStarterPosition.offset >= 0) {
      if (eventHasVirtDuration) {
        currentDuration = currentStarterPosition.timeOffset || null;
        progress = (currentStarterPosition.timeOffset || 0) / eventVirtualRaceDuration;
        break;
      }
      const firstOffset =
        firstTimekeepping?.properties.offset != null ? firstTimekeepping?.properties.offset : 0;
      currentDistance = currentStarterPosition.offset;
      progress = (currentStarterPosition.offset - firstOffset) / eventRaceDistance;
      break;
    }
  }
  return { progress, currentDistance, currentDuration };
}
