import { range } from 'lodash';
import type { DateTime } from 'luxon';

const fullDisabled = {
  disabledHours: () => range(0, 24),
  disabledMinutes: () => range(0, 60),
  disabledSeconds: () => range(0, 60),
};

export const calculateDisabledTime = ({
  current,
  minDate,
  maxDate,
}: {
  current: DateTime | null;
  minDate?: DateTime;
  maxDate?: DateTime;
}): {
  disabledHours?: () => Array<number>;
  disabledMinutes?: () => Array<number>;
  disabledSeconds?: () => Array<number>;
} => {
  if (minDate == null && maxDate == null) return {};
  if (current == null) return {};
  if (minDate != null && maxDate != null && minDate > maxDate)
    throw new Error('minDate must be less than maxDate');
  if (
    (minDate != null && current < minDate.startOf('day')) ||
    (maxDate != null && current > maxDate.endOf('day'))
  ) {
    return fullDisabled;
  }

  // current date is not on the same day as minDate or maxDate but between them
  if (
    (minDate == null || !current.hasSame(minDate, 'day')) &&
    (maxDate == null || !current.hasSame(maxDate, 'day'))
  ) {
    return {};
  }

  // hours
  const hours: Array<number> = range(0, 24);
  if (
    (minDate && current.hasSame(minDate, 'day')) ||
    (maxDate && current.hasSame(maxDate, 'day'))
  ) {
    const minHour = minDate?.hasSame(current, 'day') ? minDate.hour : 0;
    const maxHour = maxDate?.hasSame(current, 'day') ? maxDate.hour : 24;

    hours.splice(minHour, maxHour - minHour + 1);
  } else {
    hours.length = 0;
  }

  // minutes
  const minutes: Array<number> = range(0, 60);
  if (
    (minDate && current.hasSame(minDate, 'day') && current.hasSame(minDate, 'hour')) ||
    (maxDate && current.hasSame(maxDate, 'day') && current.hasSame(maxDate, 'hour'))
  ) {
    const minMinute =
      minDate?.hasSame(current, 'day') && minDate?.hasSame(current, 'hour') ? minDate.minute : 0;
    const maxMinute =
      maxDate?.hasSame(current, 'day') && maxDate?.hasSame(current, 'hour') ? maxDate.minute : 60;

    minutes.splice(minMinute, maxMinute - minMinute + 1);
  } else {
    minutes.length = 0;
  }

  // seconds
  const seconds: Array<number> = range(0, 60);
  if (
    (minDate &&
      current.hasSame(minDate, 'day') &&
      current.hasSame(minDate, 'hour') &&
      current.hasSame(minDate, 'minute')) ||
    (maxDate &&
      current.hasSame(maxDate, 'day') &&
      current.hasSame(maxDate, 'hour') &&
      current.hasSame(maxDate, 'minute'))
  ) {
    const minSecond =
      minDate?.hasSame(current, 'day') &&
      minDate?.hasSame(current, 'hour') &&
      minDate?.hasSame(current, 'minute')
        ? minDate.second
        : 0;
    const maxSecond =
      maxDate?.hasSame(current, 'day') &&
      maxDate?.hasSame(current, 'hour') &&
      maxDate?.hasSame(current, 'minute')
        ? maxDate.second
        : 60;

    seconds.splice(minSecond, maxSecond - minSecond + 1);
  } else {
    seconds.length = 0;
  }

  return {
    disabledHours: () => hours,
    disabledMinutes: () => minutes,
    disabledSeconds: () => seconds,
  };
};
