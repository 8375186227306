export enum AddOns {
  PREDICTIVE = 'predictive',
  PARTICIPANTS = 'participants',
  TRACKER_RENTAL = 'rent_tracker',
  MONITOR = 'monitor',
  TIMING = 'timing',
  SPONSOR = 'sponsor',
  API = 'dataFeed',
  KEYS = 'passcode_keys',
  HIGH_RESOLUTION = 'high_resolution',
}

export const isAddOn = (key: string): key is AddOns => {
  return Object.values(AddOns).includes(key as AddOns);
};
