import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { FC, PropsWithChildren } from 'react';
import { IconTurnRight } from '../../Icon';
import { Devider } from '../Devider/Devider';
import { LoadingIndicator } from '../LoadingIndicator';
import { Center, HStack } from '../MetaComponent';

interface Props {
  title: string;
  suggestions: Array<string>;
  onSuggestionsClick: (suggestion: string) => void;
  limit?: number;
  isLoading?: boolean;
  orderText?: string;
}

export const SuggestionsList: FC<Props> = ({
  title,
  suggestions,
  onSuggestionsClick,
  limit,
  isLoading,
  orderText = 'Search',
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Devider bottomSpace="3px" />
      {isLoading ? (
        <Center>
          <LoadingIndicator borderless />
        </Center>
      ) : (
        <Suggestions>
          {suggestions.map((suggestion, i) => {
            if (limit && i >= limit) return null;

            return (
              <Suggestion
                key={suggestion}
                onClick={() => onSuggestionsClick(suggestion)}
                orderText={orderText}
              >
                {suggestion}
              </Suggestion>
            );
          })}
        </Suggestions>
      )}
    </Container>
  );
};

interface SuggestionProps {
  onClick: () => void;
  orderText: string;
}

const Suggestion: FC<PropsWithChildren<SuggestionProps>> = ({ children, onClick, orderText }) => {
  return (
    <SuggestionEntry onClick={onClick}>
      <HStack>
        {children}

        <HStack gap="5px" className="call-for-action">
          <IconTurnRight />
          {orderText}
        </HStack>
      </HStack>
    </SuggestionEntry>
  );
};

const Container = styled.div``;

const Title = styled.h2`
  margin: 0px 5px;
  color: ${RacemapColors.DarkGray};
  font-weight: 500;
  font-size: 1rem;
`;

const Suggestions = styled.ul`
  all: unset;
  margin: 5px 5px 10px;
  display: flex;
  flex-direction: column;
`;

const SuggestionEntry = styled.li`
  all: unset;
  padding: 5px;
  font-weight: 300;

  .call-for-action {
    display: none;
    color: ${RacemapColors.DarkGray};
  }
        
  &:hover {
    background-color: ${RacemapColors.LightGray};
    border-radius: 5px;
    cursor: pointer;

    .call-for-action {
      display: flex;
    }
  }
`;
