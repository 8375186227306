import { TRACKER_MESSAGE_HISTORY_RECORD_STATE } from '@racemap/sdk/schema/trackers';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { ordinalSuffix } from '@racemap/utilities/functions/utils';
import type { Immutable } from 'immer';
import type { ManagedTrackerMessages } from '../../../store/trackers/trackers_reducers';
import {
  IconCalendar,
  IconCalendarXMark,
  IconCalenderCheck,
  IconErase,
  IconFail,
  IconOk,
  IconRefresh,
  IconSent,
  IconWaiting,
} from '../../Icon';
type TrackerMessages = Array<Immutable<ManagedTrackerMessages>>;

export enum TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND {
  PENDING = 'PENDING',
  PLANNED_FOR = 'PLANNED_FOR',
  CLOSE = 'CLOSE',
  NOT_SENT = 'NOT_SENT',
  PLANNED_AT = 'PLANNED_AT',
  NOT_ACKNOWLEDGED = 'NOT_ACKNOWLEDGED',
}

export type TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED =
  | TRACKER_MESSAGE_HISTORY_RECORD_STATE
  | TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND;

// historyRecords: LIFO -> elment 0 is the most recent
export function getCurrentMessageStatusCode(
  message: Immutable<ManagedTrackerMessages>,
): TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED {
  return (
    message.historyRecords?.[0]?.statusCode || TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PENDING
  );
}
export function getMostRecentMessageChangeDate(message: Immutable<ManagedTrackerMessages>): Date {
  return message.historyRecords?.[0]?.createdAt || message.createdAt;
}
export const hasStatusCodeMessages = (
  messages: TrackerMessages,
  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED,
) => {
  return (
    messages.filter((message) => getCurrentMessageStatusCode(message) === statusCode).length !== 0
  );
};

export const generateMessageSteps = (message: Immutable<ManagedTrackerMessages>) => {
  const steps: Array<{
    state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED;
    date: Date;
    title: string;
    description: string | JSX.Element;
  }> = [];

  if (message.historyRecords) {
    for (const record of message.historyRecords) {
      steps.push({
        state: record.statusCode,
        date: record.createdAt,
        title: getMessageHistoryRecordInfo(record.statusCode).title,
        description: record.statusMessage || '',
      });
    }
  }

  steps.push({
    state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.CLOSE,
    date: message.createdAt,
    title: 'Created',
    description: 'Message created',
  });

  if (!isMessageComplete(message)) {
    if (isMessageNotSent(message)) {
      // show timeout only if message is not complete and is timed out
      steps.push({
        state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.NOT_SENT,
        date: message.closeAfter,
        title: `${ordinalSuffix(getCurrentAttemptNumber(message))} Sent Failed`,
        description: 'Message was not sent.',
      });
    } else if (isMessageNotAcknowledged(message)) {
      steps.push({
        state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.NOT_ACKNOWLEDGED,
        date: message.closeAfter,
        title: 'Not Acknowledged',
        description: 'The message has not been acknowledged.',
      });
    } else {
      // show close only if message is not complete and is not timed out
      steps.push({
        state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.CLOSE,
        date: message.closeAfter,
        title: 'Close',
        description: 'Message will be closed',
      });
    }

    // show planned only when the planned date is in the future
    if (message.plannedAt > new Date()) {
      steps.push({
        state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PLANNED_FOR,
        date: message.plannedAt,
        title: 'Planned For',
        description: 'Message is scheduled to be sent.',
      });
    } else if (
      Math.abs(new Date(message.plannedAt).valueOf() - new Date(message.createdAt).valueOf()) > 2000
    ) {
      steps.push({
        state: TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PLANNED_AT,
        date: message.plannedAt,
        title: 'Planned At',
        description: 'Message was scheduled.',
      });
    }
  }

  steps.sort((a, b) => {
    return b.date.valueOf() - a.date.valueOf();
  });

  return steps;
};

export const getMessageHistoryRecordInfo = (
  statusCode: TRACKER_MESSAGE_HISTORY_RECORD_STATE_EXTENDED,
  numberOfAttempts?: number,
) => {
  switch (statusCode) {
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.ERROR:
      return {
        icon: <IconFail style={{ color: RacemapColors.DarkRed }} fixedWidth />,
        title: 'Error',
        color: RacemapColors.DarkRed,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.DELIVERED:
      return {
        icon: <IconSent style={{ color: RacemapColors.DarkYellow }} fixedWidth />,
        title: 'Delivered',
        color: RacemapColors.DarkYellow,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.COMPLETE:
      return {
        icon: <IconOk style={{ color: RacemapColors.BaseGreen }} fixedWidth />,
        title: 'Complete',
        color: RacemapColors.BaseGreen,
      };

    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.SENT:
      return {
        icon: <IconSent style={{ color: RacemapColors.DarkYellow }} fixedWidth />,
        title: 'Sent',
        color: RacemapColors.DarkYellow,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.REVOKED:
      return {
        icon: <IconRefresh style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Revoked',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE.CLEARED:
      return {
        icon: <IconErase style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Cleared',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.NOT_SENT:
      return {
        icon: <IconSent style={{ color: RacemapColors.DarkRed }} fixedWidth />,
        title: numberOfAttempts ? `${ordinalSuffix(numberOfAttempts)} Sent Failed` : ' Sent Failed',
        color: RacemapColors.DarkRed,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.NOT_ACKNOWLEDGED:
      return {
        icon: <IconFail style={{ color: RacemapColors.DarkRed }} fixedWidth />,
        title: 'Not Acknowledged',
        color: RacemapColors.DarkRed,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PENDING:
      return {
        icon: <IconWaiting style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Pending',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PLANNED_FOR:
      return {
        icon: <IconCalendar style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Planned For',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.PLANNED_AT:
      return {
        icon: <IconCalenderCheck style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Planned At',
        color: RacemapColors.DarkGray,
      };
    case TRACKER_MESSAGE_HISTORY_RECORD_STATE_FRONTEND.CLOSE:
      return {
        icon: <IconCalendarXMark style={{ color: RacemapColors.DarkGray }} fixedWidth />,
        title: 'Close',
        color: RacemapColors.DarkGray,
      };
  }
};

export const isMessageComplete = (message: Immutable<ManagedTrackerMessages>): boolean => {
  const messageStatusCode = getCurrentMessageStatusCode(message);
  return (
    messageStatusCode === 'COMPLETE' ||
    messageStatusCode === 'DELIVERED' ||
    messageStatusCode === 'ERROR' ||
    messageStatusCode === 'REVOKED' ||
    messageStatusCode === 'CLEARED'
  );
};

export const isMessageClosed = (message: Immutable<ManagedTrackerMessages>): boolean => {
  return message.closeAfter.valueOf() < Date.now();
};

export const getSentAttempts = (message: Immutable<ManagedTrackerMessages>): number =>
  message.historyRecords?.filter(
    (record) => record.statusCode === TRACKER_MESSAGE_HISTORY_RECORD_STATE.SENT,
  ).length || 0;

export const getCurrentAttemptNumber = (message: Immutable<ManagedTrackerMessages>): number => {
  return isMessageNotSent(message) ? getSentAttempts(message) + 1 : getSentAttempts(message);
};

export const isMessageNotSent = (message: Immutable<ManagedTrackerMessages>): boolean => {
  if (!isMessageClosed(message)) return false;
  if (isMessageComplete(message)) return false;

  return getSentAttempts(message) < message.maxNumOfAttempts;
};

export const isMessageNotAcknowledged = (message: Immutable<ManagedTrackerMessages>): boolean => {
  if (!isMessageClosed(message)) return false;
  if (isMessageComplete(message)) return false;

  return getSentAttempts(message) >= message.maxNumOfAttempts;
};
