import styled from '@emotion/styled';
import type { ObjectId } from '@racemap/sdk/schema/base';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { type FC, Suspense } from 'react';
import { useUserInfo } from '../../../lib/customHooks';
import { IconExclamationTriangle } from '../../Icon';
import { ErrorBoundary } from '../ErrorBoundry';
import { HStack } from '../MetaComponent';
import { Placeholder, PlaceholderAnimations } from '../Placeholder';

interface Props {
  userId?: string | ObjectId | null;
  suffix?: string;
  prefix?: string;
}

export const UserLabelText: FC<Props> = ({ userId, suffix, prefix }) => {
  const { user } = useUserInfo(userId);
  const text = user?.name || user?.email || user?.id.toHexString() || 'Unknown User';

  return (
    <HStack title={text} gap="3px">
      {isNotEmptyString(prefix) && <AdditionalText>{prefix}</AdditionalText>}
      {text}
      {isNotEmptyString(suffix) && <AdditionalText>{suffix}</AdditionalText>}
    </HStack>
  );
};

const ErrorFallback = ({ errorMessage, userId }: { errorMessage?: string; userId: string }) => {
  const title = getErrorMessageTitle(errorMessage);

  return (
    <>
      <IconExclamationTriangle
        color={RacemapColors.DarkGray}
        title={title}
        style={{ marginRight: 5, marginLeft: 5 }}
      />
      {userId}
    </>
  );
};

const getErrorMessageTitle = (errorMessage?: string) => {
  if (errorMessage?.includes('Status: 404')) {
    return 'User not found';
  }

  return 'User could not be loaded';
};

export const UserLabel: FC<Props> = (props) => {
  const { userId } = props;
  if (userId == null) return null;

  return (
    <ErrorBoundary
      fallback={({ errorMessage }) => (
        <ErrorFallback userId={userId.toString()} errorMessage={errorMessage} />
      )}
    >
      <Suspense fallback={<Placeholder animation={PlaceholderAnimations.GLOW} width="200px" />}>
        <UserLabelText {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

const AdditionalText = styled.div`
 color: ${RacemapColors.DarkGray};
 `;
