import type { ObjectId } from '@racemap/sdk/schema/base';
import { type UserOverview, UserOverviewSchema } from '@racemap/sdk/schema/user';
import { type DateFilter, RacemapAPIClient } from '@racemap/utilities/api-client';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { produce } from 'immer';
import type { Immutable } from 'immer';
import type { StoreApi } from 'zustand';
import { type DraftState, type State, useStore } from './reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export enum SearchByOptions {
  DATE = 'DATE',
  USER = 'USER',
}

export interface LoadDataParams {
  searchBy: SearchByOptions;
  dateFilter: Array<DateFilter>;
  toDate: Date;
  fromDate: Date;
  userIdToSearchFor: ObjectId | null;
}

export interface ManageEventsState {
  manageEvents: {
    events: Map<string, RacemapEvent>;
    users: Map<string, UserOverview>;
    isLoading: boolean;

    actions: {
      _loadEvents: (params: LoadDataParams) => Promise<void>;
      _loadUsers: () => Promise<void>;
      loadData: (params: LoadDataParams) => Promise<void>;
    };
  };
}

export const createManageEventsStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): Immutable<ManageEventsState> => ({
  manageEvents: {
    events: new Map(),
    users: new Map(),
    isLoading: false,
    actions: {
      _loadEvents: async ({ searchBy, dateFilter, toDate, fromDate, userIdToSearchFor }) => {
        let events: Array<RacemapEvent>;

        if (searchBy === SearchByOptions.DATE) {
          if (dateFilter.length === 0) {
            set(
              produce((s: DraftState) => {
                s.manageEvents.events = new Map();
              }),
            );
            return;
          }

          events = await apiClient.getEvents({
            dateFilter: [...dateFilter],
            toDate: toDate,
            fromDate: fromDate,
            show: ['hidden'],
            filter: 'can-edit',
            attachRenewedAt: 'true',
          });
        } else {
          if (userIdToSearchFor == null) {
            set(
              produce((s: DraftState) => {
                s.manageEvents.events = new Map();
              }),
            );
            return;
          }

          console.log({ userIdToSearchFor });

          events = await apiClient.getEvents({
            show: ['hidden'],
            attachRenewedAt: 'true',
            findByCreator: userIdToSearchFor.toHexString(),
            findByEditor: userIdToSearchFor.toHexString(),
          });
        }

        set(
          produce((s: DraftState) => {
            s.manageEvents.events = new Map(events.map((e) => [e.id, e]));
          }),
        );
      },
      _loadUsers: async () => {
        const { users } = await apiClient.getUsers({ withEventStats: true });

        set(
          produce((s: DraftState) => {
            s.manageEvents.users = new Map(users.map((u) => [u.id, UserOverviewSchema.parse(u)]));
          }),
        );
      },
      loadData: async (params) => {
        try {
          set(
            produce((s: DraftState) => {
              s.manageEvents.isLoading = true;
            }),
          );

          const state = get().manageEvents;
          await Promise.all([state.actions._loadEvents(params), state.actions._loadUsers()]);
        } finally {
          set(
            produce((s: DraftState) => {
              s.manageEvents.isLoading = false;
            }),
          );
        }
      },
    },
  },
});

export const useIsLoading = () => useStore((s) => s.manageEvents.isLoading);
