import { Zodios, makeApi } from '@zodios/core';
import type { AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';
import { customValidationPlugin } from './apiPlugins/customValidationPlugin';
import { billingApi } from './apis/billing';
import { messageTemplatesApi } from './apis/messageTemplates';
import { trackerHistoryServiceApi } from './apis/services/tracker-history';
import { supportApi } from './apis/support';
import { trackerTagsApi } from './apis/trackerTags';
import { trackersApi } from './apis/trackers';

type ClientProps = {
  requestConfig?: AxiosRequestConfig;
};

export const api = makeApi([
  ...trackersApi,
  ...trackerTagsApi,
  ...trackerHistoryServiceApi,
  ...supportApi,
  ...messageTemplatesApi,
  ...billingApi,
]);
export const apiClient = new Zodios(api);
apiClient.use(customValidationPlugin());

export class RacemapV2APIClient extends Zodios<typeof api> {
  _host: string;
  _props: ClientProps;

  constructor(host: string, props: ClientProps = {}) {
    const headers = props.requestConfig?.headers || {};
    if (process.env.TEST_MODE === 'integration') {
      headers['x-integration-test'] = 'true';
    }

    super(api, { axiosConfig: { baseURL: host, ...props.requestConfig, headers } });
    this.use(customValidationPlugin());

    this._host = host;
    this._props = props;
  }

  static fromWindowLocation(includeCredentials = true): RacemapV2APIClient {
    return new RacemapV2APIClient(window.location.origin, {
      requestConfig: {
        withCredentials: includeCredentials,
      },
    });
  }

  static asSuperUser(host: string, token?: string): RacemapV2APIClient {
    const superUserApiToken = process.env.SUPER_USER_API_TOKEN || token;
    if (superUserApiToken == null || superUserApiToken === '') {
      throw new Error('Super User Password is invalid');
    }
    return new RacemapV2APIClient(host, {
      requestConfig: {
        headers: {
          Authorization: `Bearer ${superUserApiToken}`,
          userAgent: process.env.SERVICE_NAME,
        },
      },
    });
  }

  static fromEnvVars(): RacemapV2APIClient {
    const host = process.env.API_HOST || process.env.HOST;
    if (host == null || host === '') {
      throw new Error('API_HOST is invalid or missing');
    }
    const superUserApiToken = process.env.SUPER_USER_API_TOKEN;
    if (superUserApiToken == null || superUserApiToken === '') {
      throw new Error('SUPER_USER_API_TOKEN is invalid or missing');
    }
    return new RacemapV2APIClient(host, {
      requestConfig: {
        headers: {
          Authorization: `Bearer ${superUserApiToken}`,
          userAgent: process.env.SERVICE_NAME,
        },
      },
    });
  }

  deriveAnonymousClient(): RacemapV2APIClient {
    return new RacemapV2APIClient(this._host);
  }
}

export {
  trackersApi,
  trackerTagsApi,
  supportApi,
  messageTemplatesApi,
  billingApi,
  trackerHistoryServiceApi,
  AxiosError,
};
