import styled from '@emotion/styled';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { isEmptyString, isNotEmptyString } from '@racemap/utilities/functions/utils';
import { isValidRREventId } from '@racemap/utilities/functions/validation';
import { RACERESULT_KEYS } from '@racemap/utilities/service-utils/rrImport';
import { RRImportMode, type RacemapEvent } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { type FC, useEffect, useState } from 'react';
import type { CurrentEvent } from '../../../store/events/events_reducers';
import type { Entry } from '../../BasicComponents/SwitchList';

export const DAYS_IMPORT_STILL_RUN = 3;

export const eventIsOver = (event: Immutable<RacemapEvent | CurrentEvent>): boolean => {
  return (
    event.endTime == null ||
    DateTime.fromISO(event.endTime) < DateTime.utc().minus({ days: DAYS_IMPORT_STILL_RUN })
  );
};

export const autoImportIsActive = (event: Immutable<RacemapEvent | CurrentEvent>): boolean => {
  return (
    !eventIsOver(event) &&
    (event.integrations.genericStarterImport.isActive ||
      (event.integrations.raceresult.isActive && event.integrations.raceresult.contests.length > 0))
  );
};

const apiClient = RacemapAPIClient.fromWindowLocation();

interface Counts {
  records: number;
  recordsWithId: number;
  recordsPerContest: Record<string, number>;
}

export interface RRImportReport {
  contests: Array<string>;
  counts: Counts | null;
  error: string | null;
  isLoading: boolean;
  sourceUrl: string;
  sourceStatus: 'danger' | 'success' | 'warning' | undefined;
  triggerReload: () => void;
}

type RRStarterRecords = Array<Array<number | string>> | { error?: string };

export const useRRImport = (event: Immutable<CurrentEvent> | null): RRImportReport => {
  const [sourceStatus, setSourceStatus] = useState<RRImportReport['sourceStatus']>();
  const [contests, setContests] = useState<RRImportReport['contests']>([]);
  const [counts, setCounts] = useState<RRImportReport['counts']>(null);
  const [error, setError] = useState<RRImportReport['error']>(null);
  const [isLoading, setIsLoading] = useState<RRImportReport['isLoading']>(false);
  const [sourceUrl, setSourceUrl] = useState<string>('');

  async function validateRRImport(options: { forceReload?: boolean } = {}) {
    try {
      if (event == null) return;

      const rrImportConf = event.integrations.raceresult;
      const isActivated = rrImportConf.isActive;
      if (!isActivated) return;

      const url = getRRImportUrl(rrImportConf);
      if (url == null) {
        setSourceUrl('');
        setSourceStatus(undefined);
        setContests([]);
        setCounts(null);
        setError(null);
        return;
      }
      if (!options.forceReload && url.href === sourceUrl) return;

      setSourceUrl(url.href);
      setIsLoading(true);
      setSourceStatus(undefined);
      const response = await apiClient.getRemoteContent(url.href);
      if (!response.ok) throw new EventFetchError(`Error fetch event: ${response.statusText}`);
      const rrStarterRecords: RRStarterRecords = await response.json();

      if (rrStarterRecords == null) throw new EmptyEventError(`Find no data on this url: ${url}`);
      if (typeof rrStarterRecords === 'object' && !Array.isArray(rrStarterRecords)) {
        if (rrStarterRecords.error?.includes('access not granted'))
          throw new EventFetchError(
            "Can't access the data. Check the permissions of the event and if you have set a valid event id in integrations.",
          );

        if (rrStarterRecords.error != null)
          throw new EventFetchError(`Fetch failed: ${rrStarterRecords.error}`);

        throw new EventFetchError(
          `Failed to load starters from RACE RESULT. Check url to get more infos: ${url}`,
        );
      }

      const res = analyseRecords(rrStarterRecords);
      setContests(res.contests);
      setCounts(res.counts);
      setSourceStatus('success');
      setIsLoading(false);
      setError(null);
    } catch (err) {
      if (err instanceof Error) {
        setError(`${err.name}: ${err.message}`);
        setSourceStatus('danger');
        setContests([]);
        setCounts(null);
        setIsLoading(false);
        setSourceUrl('');
      }
    }
  }

  useEffect(() => {
    validateRRImport();
  }, [event]);

  const triggerReload = () => {
    validateRRImport({ forceReload: true });
  };

  return { sourceStatus, contests, counts, error, isLoading, sourceUrl, triggerReload };
};

export const getRRImportUrl = (
  config: Immutable<RacemapEvent>['integrations']['raceresult'],
): URL | null => {
  const eventId = config.eventId;
  const starterListUrl = config.starterListUrl;
  const mode = config.importMode;

  if (mode === RRImportMode.EVENT_ID) {
    if (isEmptyString(eventId)) return null;
    if (!isValidRREventId(eventId)) throw new Error('Invalid RACE RESULT Event ID!');
    const intId = Number.parseInt(eventId, 10);

    return new URL(`https://api.raceresult.com/${intId}/RACEMAP_89G1FZBN0RZAOPZ08L77WHAJSR06K2NZ`);
  }
  if (isEmptyString(starterListUrl)) return null;
  try {
    return new URL(starterListUrl);
  } catch (err) {
    if (err instanceof Error) {
      throw new Error('Invalid URL of the starter list!');
    }

    return null;
  }
};

const analyseRecords = (
  rrStarterRecords: Array<Array<number | string>>,
): {
  contests: RRImportReport['contests'];
  counts: RRImportReport['counts'];
} => {
  const contestIndex = RACERESULT_KEYS.Contest;
  const recordIdIndex = RACERESULT_KEYS.Id;
  const contests = new Set<string>();
  const ids = new Set<string>();
  const recordsPerContest: Record<string, number> = {};

  for (const record of rrStarterRecords) {
    const contest = record[contestIndex].toString();
    const id = record[recordIdIndex].toString();
    if (isNotEmptyString(contest)) contests.add(contest);
    if (isNotEmptyString(id)) {
      if (ids.has(id))
        throw new DuplicateIdError(
          `Starter ${record[RACERESULT_KEYS.RaceNr]} - ${record[RACERESULT_KEYS.Firstname]} ${
            record[RACERESULT_KEYS.Lastname]
          } has the already used id ${id}!`,
        );
      ids.add(id);
    }

    recordsPerContest[contest] = (recordsPerContest[contest] || 0) + 1;
  }

  return {
    contests: Array.from(contests),
    counts: { records: rrStarterRecords.length, recordsWithId: ids.size, recordsPerContest },
  };
};

export const getContestsWithStatus = (
  contests: Immutable<Array<string>>,
  possibleContests: Array<string>,
  sourceStatus: RRImportReport['sourceStatus'],
  isLoading: boolean,
  counts: RRImportReport['counts'],
): Array<Entry> => {
  const combinedContests = Array.from(new Set([...contests, ...possibleContests]))
    .sort((a, b) => sortContests(a, b, counts))
    .filter(isNotEmptyString);

  return combinedContests.map((c) => ({
    value: contests.includes(c),
    switchId: c,
    label: <ContestLabel contestName={c} starterCount={counts?.recordsPerContest[c]} />,
    disabled: isLoading,
    ...getSwitchState(c, contests, possibleContests, sourceStatus),
  }));
};

const sortContests = (a: string, b: string, counts: RRImportReport['counts']): number => {
  if (counts == null) return 0;
  const aCount = counts.recordsPerContest[a] || 0;
  const bCount = counts.recordsPerContest[b] || 0;

  if (aCount === bCount) return a.localeCompare(b);
  return bCount - aCount;
};

const ContestLabel: FC<{ contestName: string; starterCount?: number }> = ({
  contestName,
  starterCount,
}) => (
  <Label>
    <ContestName>{contestName}</ContestName>
    <StarterCount>{starterCount || 0} Starter</StarterCount>
  </Label>
);

const Label = styled.div`
  display: flex;
  align-items: center;
`;
const ContestName = styled.div`
  font-weight: 500;
  min-width: 100px;
`;
const StarterCount = styled.div`
  margin-left: 5px;
  color: ${RacemapColors.DarkGray};
`;

const getSwitchState = (
  contestName: string,
  contests: Immutable<Array<string>>,
  possibleContests: Array<string>,
  sourceStatus: RRImportReport['sourceStatus'],
): { switchLabel: string; variant?: 'success' | 'warning' | 'danger'; title?: string } => {
  if (sourceStatus == null && contests.includes(contestName)) {
    return { switchLabel: 'UNKNOWN', variant: 'warning', title: 'Set source url or event id!' };
  }
  if (sourceStatus === 'danger') {
    return { switchLabel: 'NO SYNC', variant: 'danger', title: 'Found no valid source file!' };
  }
  if (possibleContests.includes(contestName) && contests.includes(contestName))
    return { switchLabel: 'SYNC', variant: 'success' };
  if (possibleContests.includes(contestName) && !contests.includes(contestName))
    return { switchLabel: 'NO SYNC' };
  return {
    switchLabel: 'UNKNOWN',
    variant: 'warning',
    title: 'Found no starter with that content!',
  };
};

class EmptyEventError extends Error {}
class EventFetchError extends Error {}
class DuplicateIdError extends Error {}
