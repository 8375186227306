import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { DataNode } from 'antd/es/tree';
import type { Key } from 'react';
import { CustomDataNodeDraggable } from './CustomDataNodeDraggable';

export const draggableDataNode = (
  title: React.ReactNode,
  key: Key,
  count: number | undefined,
): DataNode => {
  return {
    title: <CustomDataNodeDraggable title={title} keyNode={key} count={count ?? 0} />,
    key,
    style: { padding: '0px 0px 0px 0px', width: '100%' },
  };
};

export const customDataNode = (
  title: React.ReactNode,
  key: Key,
  count: number | undefined,
  children?: Array<DataNode>,
): DataNode => {
  return {
    title: (
      <Container>
        <span>{title}</span>
        <CountField>{count}</CountField>
      </Container>
    ),
    key,
    children,
    style: { padding: '0px 0px 0px 0px', width: '100%' },
  };
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CountField = styled.div`
  color: ${RacemapColors.DarkGray};
  margin-left: 4px;
`;
