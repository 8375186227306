import type { EntityEvent, EntityEvents } from '@racemap/sdk/schema/entityEvents';
import type { PointWithSpeedAndOffset } from '../functions/timing';
import type { RacemapStarter } from './events';

export interface ProgressInfo {
  progress: number;
  passedTimekeepings?: number;
  timekeepingsTotal?: number;
  rank?: number;
  currentDistance: number | null;
  currentDuration: number | null;
}

export enum StarterStatus {
  DIDNOTFINISH = 'DNF',
  DIDNOTSTART = 'DNS',
  DISQUALIFIED = 'DSQ',
  FINISHED = 'FIN',
}

export type StarterResults = Array<StarterResult>;
export interface StarterResult extends RacemapStarter {
  alerts: EntityEvents;
  progress: ProgressInfo | null;
  lastPoint: PointWithSpeedAndOffset | null;
  gpsFix: boolean | null;
  online: boolean | null;
  active: boolean | null;
  battery: number | null;
  trackerActivated: boolean;
  deviation: number | null;
  currentSpeed: number | null;
  currentMove: number | null;
  lastAlert: EntityEvent | null;
  track: Array<PointWithSpeedAndOffset> | null;
  status: null | StarterStatus;
}
